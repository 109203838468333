.receive {
  width: auto;
  justify-self: start;
  display: grid;
  grid-template-columns: 30px auto 94px;
  grid-template-rows: auto;
  align-items: center;
  align-content: center;
  align-self: last baseline;
  max-width: clamp(0px, 100%, 500px);
  padding: 0 0 0 10px;
  padding-left: clamp(10px, 3vw, 25px);
  scroll-snap-align: end;

  &:has(.image-msg) {
    min-width: clamp(0px, calc(100% - 15px), 500px);
  }

  &:hover .reply {
    > span {
      transform: scale(1);
    }
  }

  .reply {
  }

  &-container {
    transition: 0.3s ease;
    scroll-snap-type: x mandatory;
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 100% 100px;

    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    .reply_alt {
      scroll-snap-align: none;
      align-self: center;
      padding-left: 10px;

      span {
        position: sticky;
        left: 10px;
        right: 0px;
        width: auto;
        text-align: center;
        padding-right: 20px;
      }

      // scroll-snap-align: none;
    }
  }
}
.receiveMsg {
  box-shadow: 0 0 1px 1px #0d1117;
  margin: 1px;
  height: auto;
  justify-content: start;
  background-color: #2b3543;
  word-break: break-all;
  border-radius: 12px;
  border-bottom-left-radius: 0;
  display: grid;
  height: auto;
  align-items: center;
  align-content: center;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 1fr;
  padding: 10px 10px 6px;
  // min-width: 80px;

  &.image-msg {
    border: 4px solid #2b3543;
  }
}

.receive_reply {
  color: #ffffff;
  transition: 0.3s ease;
  padding-left: 10px;

  > span {
    &:first-child {
      transition-delay: 0.1s;
    }
    &:last-child {
      transition-delay: 0.15s;
    }
  }
}

.reply_alt {
  font-size: 16px;
  color: #fed400 !important;
  justify-self: start;
  transition: 0.1s ease;
}

.receive_message {
  word-break: break-word;
  white-space: pre-wrap;
  font-size: 16px;
  font-weight: 500;
  //   background-color: red;
  // padding: 5px;
  border-radius: 30rem;
  padding-right: 55px;
  color: #fff;
}

.receive_replied {
  background-color: #ffffff1d;
  border-left: 3.5px solid #1a73e8;
  color: #ffffffbf;
}

.thumb {
  display: grid;
  // background-color: #1a73e8;
  width: 15px;
  height: 25px;
  align-self: last baseline;
  margin-bottom: 5px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

#cluster {
  margin-bottom: 0.3px;

  .receiveMsg {
    border-radius: 3px 15px 15px 3px;
  }
  .sentMsg {
    border-radius: 15px 3px 3px 15px;
  }
  .thumb {
    visibility: hidden;
  }
}

#startCluster {
  margin-bottom: 0.3px;
  .receiveMsg {
    border-radius: 15px 15px 15px 3px;
  }
  .sentMsg {
    border-radius: 15px 15px 3px 15px;
  }
  .thumb {
    visibility: hidden;
  }
}

#endCluster {
  margin-bottom: 15px;
  margin-top: 0px;
  .receiveMsg {
    border-radius: 3px 15px 15px 15px;
  }
  .sentMsg {
    border-radius: 15px 3px 15px 15px;
  }
  .thumb {
    visibility: visible !important;
  }
}
#singleCluster {
  margin: 0 0 15px 0;
  .receiveMsg,
  .sentMsg {
    border-radius: 15px !important;
  }
  .thumb {
    visibility: visible !important;
  }
}

// @media screen and (min-width: 500px) {
//   .receive_reply_alt {
//     span {
//       max-width: 90vw !important;
//     }
//   }
// }
