.error_page {
  display: grid;
  padding: 30px;
  align-content: center;
  justify-content: center;
  justify-items: center;
  color: #ffffff;
  grid-gap: 40px;
  align-self: start;
  grid-template-rows: 120px;
}

.oops {
  font-size: 40px;
  font-weight: 500;
}

.err_msg {
  font-size: 16px;
  text-align: center;
}

p {
  font-family: "Onest", monospace;
  font-style: italic;
  font-size: 15px;
}
