:root {
  --land-pad: clamp(15px, 1vw, 20px);
  --land-menu-display: grid;
  --land-magn-display: none;
  --button-text-state: none;
  --button-height: 40px;
  --button-width: 25px;
  --land-head-height: 100px;
  --land-menu-state: grid;
}

.landing {
  &-header {
    background-color: #0d1117c5;
    backdrop-filter: blur(10px);
    height: calc(var(--land-head-height) - 14px);
    padding: 0 var(--land-pad);
    width: calc(100vw - 2 * var(--land-pad));
    position: fixed;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr auto;
    z-index: 1000;
    top: 0;
    left: 0;

    &.land-head-border {
      border-bottom: 1px solid #2b3543;
    }
  }
  &-logo {
    align-self: center;
    max-width: 180px;
    overflow: hidden;
    justify-self: center;
    z-index: 1;
    img {
      margin-left: -30px;
      width: 240px;
      cursor: pointer;
      user-select: none;
    }
  }
  &-menu-icon {
    overflow: hidden;
    display: var(--land-menu-state);
    color: #fff;
    align-items: center;
    padding-left: 10px;
    span {
      z-index: 1;
      font-size: 32px;
      cursor: pointer;
    }
  }
  &-menu {
    display: var(--land-menu-display);
    grid-auto-flow: column;
    color: #ffffff;
    justify-self: center;
    height: 80%;
    align-self: center;
    // align-items: center;
    position: relative;

    &-items {
      text-transform: capitalize;
      font-size: 18px;
      opacity: 0.9;
      cursor: pointer;
      z-index: 1;
      padding: 0 20px;
      height: 100%;
      display: grid;
      align-content: center;
    }
    &:hover > span {
      opacity: 1;
      transition: 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }

    :nth-child(1):hover ~ span {
      width: 50px;
      left: 5px;
    }
    :nth-child(2):hover ~ span {
      left: 83px;
      width: 86px;
    }
    :nth-child(3):hover ~ span {
      left: 203px;
      width: 86px;
    }
    :nth-child(4):hover ~ span {
      left: 324px;
      width: 62px;
    }

    .land-magnifier {
      position: absolute;
      align-self: center;
      display: var(--land-magn-display);
      //   justify-self: start;
      background-color: #ffffff10;
      height: 45px;
      padding: 0 10px;
      z-index: -1;
      opacity: 0;
      border-radius: 6px;
    }
  }
  &-action {
    justify-self: end;
    align-self: center;
    padding-right: 16px;
  }
  &-button {
    border-radius: 10rem;
    text-align: center;
    font-weight: 500;
    border: 2px solid #0e83e6;
    color: #0291e3;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: 0.7s cubic-bezier(0.025, 0.27, 0.155, 0.87) color;
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: var(--button-height);
    width: var(--button-width);
    padding: var(--land-button-pad);

    > :first-child {
      display: var(--button-text-state);
    }

    > :last-child {
      overflow: hidden;
    }

    &::after {
      position: absolute;
      align-self: center;
      content: "";
      width: 130%;
      height: 100%;
      left: -130%;
      border-radius: 10rem;
      z-index: -1;
      background-color: #0e83e6;
      transition: 0.7s cubic-bezier(0.025, 0.27, 0.155, 0.87);
    }

    &:hover {
      transition-delay: 0.3s;
      color: #ffffff;

      &::after {
        left: -5%;
        transition-delay: 0.3s;
        border-radius: 0;
        height: 200%;
      }
    }
  }
}

@media screen and (min-width: 920px) {
  :root {
    --land-pad: clamp(15px, 3vw, 80px);
    --land-menu-display: grid;
    --land-magn-display: block;
    --button-text-state: block;
    --button-width: auto;
    --button-height: 45px;
    --land-head-height: 125px;
    --land-menu-state: none;
  }
}

@media screen and (max-width: 920px) {
  .landing-menu {
    position: fixed;
    grid-auto-flow: row;
    height: 100vh;
    align-content: start;
    row-gap: 24px;
    top: 81px;
    padding: 0;
    left: 0;
    width: clamp(200px, 90vw, 250px);
    column-gap: auto;
    overflow-x: auto;
    overflow: hidden;
    z-index: 0;
    translate: -300px;
    visibility: hidden;
    transition: 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95) translate,
      0s ease 0.4s visibility;

    &#show {
      transition: 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95) translate;
      visibility: visible;
      translate: 0;
    }
    &#hide {
      visibility: hidden;
    }

    &-items {
      padding: 12px 25px;
      width: 100%;

      &:hover {
        background-color: #2b3543;
      }
    }

    &::after {
      content: "";
      position: absolute;
      background-color: #0d1117;
      backdrop-filter: blur(10px);
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
}
