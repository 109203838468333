.loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #000000c6;
  display: grid;
  align-content: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 1000;

  i {
    font-size: 28px;
    color: #fff;
  }
}

.wheel {
  transform: scale(0.6);
  animation: load 1.2s infinite;
}

@keyframes load {
  0% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(0.6);
  }
}
