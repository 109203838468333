html,
body {
  overflow-x: hidden !important;
}

.chatBox {
  display: grid;
  justify-content: center;
  grid-template-columns: 680px;
  grid-template-rows: auto;
  max-height: 100%;
  max-width: 698px;
  justify-self: center;
  height: calc(100% - 2px);
  overflow: hidden !important;
  background: url("../../../public/yogrt.png");
  background-position: top;
  background-size: cover;
  background-repeat: repeat;

  .notifyContainer {
    color: #ffffff;
    background-color: #fff;
    height: 0px !important;
    bottom: 44px;
    > div {
      border-radius: 12px;
      padding: 8px 16px;
    }
  }
}

.chatContainer {
  position: relative;
  overflow: hidden !important;
  border-left: 1px solid #ffffff17;
  border-right: 1px solid #ffffff17;
  display: grid;
  grid-template-rows: auto 1fr auto;
  align-content: start;
  grid-gap: 2px;
  grid-template-columns: 1fr;
  align-items: last baseline;
  height: calc(100vh - 0px);
}

.chatContainer .chatHead {
  border: 1px solid #ffffff17 !important;
  padding: 0 15px;
  min-height: 50px;
  height: auto;
  top: 0;
  left: 0;
  user-select: none;
  width: calc(100% - 30px);
  margin-left: -1px;
  // position: fixed;
  z-index: 100;
  color: #fff;
  background-color: var(--bg-color);
  display: grid;
  grid-template-columns: auto auto 1fr 30px;
  grid-template-rows: 60px;
  align-items: center;
  align-content: center;

  &-icon {
    // background-color: #e6c000;
    background-color: #0a66de;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: grid;
    align-items: center;
    justify-items: center;
    // color: var(--bg-color);
    color: #fff;
    font-size: 18px;
    margin-right: 0.5vw;
  }
}

.chat_logo {
  display: grid;

  img {
    align-self: center;
    width: 30px !important;
    height: 30px !important;
  }
}

.roomName {
  font-size: 20px;
  letter-spacing: -0.2px;
  word-spacing: 2px;
  font-weight: 500;
  margin-left: 5px;
}

.delete {
  justify-self: end;
  font-size: 15px;
  cursor: pointer;
  padding-right: 10px;
}

.messageContainer {
  align-self: last baseline;
  // width: 480px;
  display: grid;
  overflow-y: auto;
  // max-width: 100%;
  grid-template-columns: 1fr;
  align-self: last baseline;
  max-height: calc(100vh - 125px);
  min-height: 0 !important;
  padding: 10px 0;
  transition: 0.1s ease;
}

.messageBox {
  display: grid;
  row-gap: 10px;
  padding-bottom: 3px;
  width: calc(100% - 20px);
}

.sendMessage {
  user-select: none;
  z-index: 100;
  border-top: 1px solid #ffffff17;
  border-bottom: 4px solid #0291e3;
  transition: 0.3s ease border;
  height: auto;
  padding: 5px 0 0px;
  min-height: 33px;
  display: grid;
  bottom: 0;
  left: 0;
  // align-content: center;
  // align-items: center;
  display: grid;
  grid-template-columns: 40px 1fr 30px;
  grid-template-rows: auto;
  background-color: var(--bg-color);
  // background-color: blue;

  .mythumb {
    user-select: none;
    text-align: center;
    font-size: 20px;
    color: #fff;
    display: grid;
    width: 50px;
    align-self: last baseline;
    padding-bottom: 10px;
  }
}

.sendMsg {
  width: 50px;
  height: 100%;
  height: 40px;
  margin-right: 5px;
  font-size: 20px;
  color: #fff;
  justify-self: center;
  color: #908d8a;
  align-self: last baseline;
  // padding-bottom: 10px;
  overflow: hidden;
  // background-color: red;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  justify-content: start;
  justify-items: start;
  grid-template-columns: repeat(2, 100%);

  span {
    cursor: pointer;
    align-self: center;
    transform: translateY(2px);
  }

  &.active {
    color: #0291e3 !important;
  }
  :first-child {
    color: #0291e3;
  }
}

#message {
  resize: none;
  border-radius: 0px;
  font-size: 16px;
  border: none !important;
  caret-color: #0291e3;
  padding: 10px 0 10px 10px;
  outline: none !important;
  font-family: inherit;

  &::selection {
    background-color: #2b3543;
  }
}

textarea:focus {
  outline: none !important;
}

textarea::placeholder {
  color: #908d8a;
  font-weight: 500 !important;
  height: 0px;
}

.replyMessage {
  color: #ffffff98;
  width: 100%;
  margin: 5px 0;
  margin-left: -25px;
  padding: 4px 40px 4px 10px;
  border-left: 3.5px solid #1a73e8;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  column-gap: 10px;
  position: relative;
  border-radius: 5px;
  align-items: center;
  background-color: #2b3543;
  overflow: hidden;

  &:has(.reply-image-thumb) {
    font-weight: 400;

    > div {
      row-gap: 2px;
    }
  }
  > div {
    height: auto;
    line-height: 18px;
    row-gap: 5px;
    display: grid;
    grid-template-rows: repeat(2, auto);
    align-content: space-between;
    padding: 5px 0;
    font-weight: inherit;

    > :last-child {
    }
  }

  .reply-image-thumb {
    display: grid;
    overflow: hidden;
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 2px;
    box-shadow: -0.3px 0.3px 0.8px 0.8px #0d1117;
    place-content: center;

    img {
      width: 100%;
      min-height: 45px;
      object-fit: cover;
    }
  }

  &-thumb {
    margin-top: 4px;
    font-weight: 600;
    color: #fff;
    font-size: 16px;
    margin-left: -2.5px;
  }
  &-msg {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: inherit;
  }

  &::after {
    height: auto;
    line-height: 00.5;
    position: absolute;
    font-size: 18px;
    padding-left: 10px;
    font-weight: 500;
    top: 82.5%;
    padding-bottom: 20px;
    background-color: #2b3543;
    width: 100%;
    left: 0;
    // content: "...";
  }
}
.replyMessage span {
  cursor: pointer;
  position: absolute;
  align-self: center;
  justify-self: end;
  margin-right: 10px;
  background-color: #2b3543;
  padding: 0px 6px;
  font-size: 13px;
  line-height: 20px;
  border: 1px solid #7a7a7a;
  border-radius: 100%;
}

.group-info {
  height: auto;
  width: auto;
  background-color: #1a2028;
  padding: 10px 15px;
  border-radius: 20px;
  display: grid;
  justify-self: center;
  grid-template-columns: repeat(2, auto);
  column-gap: 5px;
  align-items: center;
  align-content: center;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  margin-bottom: 15px;

  &:nth-child(3) {
    margin-bottom: 40px;
  }
}

.empty {
  min-height: 40px;
}

.scroll-to-bottom {
  position: fixed;
  transition: 0.2s ease;
  z-index: 100;
  justify-self: center;
  margin-bottom: 65px;
  transform: scale(0);
  span {
    font-size: 20px;
    border-radius: 50%;
    background-color: #2b3543;
    color: #fff;
    padding: 4px;
    box-shadow: 0 0 1px 1px #00000090;
  }
  &.show {
    transform: scale(1.2);
    cursor: pointer;
  }
}

.view {
  &-mobile {
    user-select: none;
  }
}

#longtext {
  padding: 8px 10px 16px;

  .time {
    transform: translateY(8px);
  }

  .message,
  .receive_message {
    padding-right: 3px !important;
  }
}

@media screen and (max-width: 700px) {
  .chatBox {
    grid-template-rows: 100vh;
    grid-template-columns: 100vw;
    border: none;
    max-height: 100vh;
    overflow-x: hidden;
  }

  .sendMessage,
  .chatHead {
    position: fixed;
  }

  .chatHead {
    width: calc(100vw - 30px);
    border-width: 0 0 1px !important;
  }

  .chatContainer {
    overflow-y: hidden !important;
    grid-template-rows: 1fr;
    align-self: center;
    height: calc(100vh - 80px);
  }

  .sendMessage {
    width: calc(100vw);
  }
}

.message-link {
  text-decoration: underline !important;
  word-break: break-all;

  &:hover {
    background-color: #ffffff35;
  }
}

.emoji {
  > div {
    font-size: 20px;
  }
  &-single {
    > div {
      font-size: 40px;
    }
  }
}

@media screen and (min-width: 750px) {
  .messageContainer {
    margin-bottom: -5px !important;
  }
}
@media screen and (min-width: 480px) {
  .container:has(.chatBox) {
    background: #0d1117 !important;
  }
}

@media screen and (max-width: 480px) {
  .container:has(.chatBox) {
    background: url("../../../public/yogrt.png");
    background-position: top;
    background-size: cover;
    background-repeat: repeat;
  }
  .empty {
    min-height: 100px;
  }
  .chatBox {
    background: transparent;
    grid-template-rows: auto;
    .chatContainer {
      border: none;
      height: calc(100dvh - 50px);
    }
  }
}
