.capture {
  &-show {
    display: grid;
  }
  &-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    backdrop-filter: blur(5px);
    background-color: #0d1117;
    height: 100%;
    width: 100vw;
    min-height: 730px;
    display: none;
    align-content: center;
  }
  &-image {
    &-container {
      position: relative;
      display: grid;
      background-color: #0d1117;
      width: auto;
      justify-self: center;
      padding: 25px;
      height: 600px;

      .appName {
        user-select: none;
        position: absolute;
        align-self: end;
        justify-self: center;
        font-size: 20px;
        height: 30px;
        padding-bottom: 25px;
      }
    }
    margin-top: -35px;
    justify-self: center;
    align-self: center;
    border-radius: 20px;
    max-width: 300px;
    width: 95vw;
    height: auto;
    min-height: 200px;
    user-select: none;
    display: grid;
    grid-template-rows: 80px auto;
    overflow: hidden;
  }
  &-head {
    background-image: linear-gradient(
      to right,
      #2e89e8,
      #1379e1,
      #1b7de2,
      #258af1
    );
    user-select: none;

    display: grid;
    align-items: center;
    justify-items: center;
  }
  &-body {
    background-color: #fff;
    color: #0d1117;
    padding: 20px;
    display: grid;
    justify-items: center;
    text-align: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    white-space: pre-wrap;
    line-height: 1.4;
    word-break: break-word;
  }
  &-options {
    position: absolute;
    height: 40px;
    width: 90vw;
    justify-self: center;
    max-width: 300px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: center;
    column-gap: 35px;
    direction: rtl;
    align-items: center;
    padding-top: 50px;
    z-index: 100;

    i {
      cursor: pointer;
      font-size: 20px;
      height: 40px;
      width: 40px;
      display: grid;
      justify-items: center;
      align-items: center;
      color: #2a2c31;
      border-radius: 50%;
      background-color: #fff;
    }
  }
}

#capture {
  &-show {
    display: grid;
  }
}
