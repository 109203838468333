.process {
  position: absolute;
  z-index: 100;
  padding: 10px;
  background-color: #1f2630;
  color: #ffffff;
  box-shadow: 0 0 4px 2px #0d1117d2;
  border-radius: 8px;
  top: 30px;
  left: 10px;
  overflow: hidden;
  max-width: clamp(100px, 90vw, 350px);
  grid-auto-flow: column;
  display: grid;
  align-content: start;
  row-gap: 12px;
  column-gap: 14px;

  .body {
    display: grid;
    grid-auto-flow: row;
    // align-content: space-between;
    align-content: center;
    padding-right: 5px;
  }

  .title {
    font-size: 16px;
    text-align: left;
    font-weight: 300;
    height: auto;
    font-size: 14px;
  }

  &-options {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 50px, 50px;
    align-content: center;
    align-items: center;
    justify-self: end;
    column-gap: 2px;
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    overflow: hidden;

    // margin-right: 40px;
  }
  span {
    cursor: pointer;
    font-size: 20px;
    padding: 7px;
    border-radius: 50%;
    transition: 0.2s ease-in;
    transform: scale(1);
    overflow: hidden;
    // margin-top: -2px;

    &#fade {
      transition: 0s;
      opacity: 0.5;
      background-color: transparent;
      cursor: wait;
    }

    &:first-child {
      color: #ff5555;
      &:hover {
        background-color: #ff555515;
      }
    }

    &.complete {
      font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 20;
      color: #28a745;
      transform: scale(1.2);

      &:hover {
        background-color: #28a74515;
      }
    }

    &:hover {
      background-color: #ffffff15;
    }
  }

  .circular-progress {
    cursor: default;
    padding: 5px;
    margin-right: 2px;
    border-radius: 100%;
    background-color: #ffffff15;
    animation: rotate 2s linear infinite;
    --progress: 0;
    --size: 22px;
    --half-size: calc(var(--size) / 2);
    --stroke-width: 3px;
    --radius: calc((var(--size) - var(--stroke-width)) / 2);
    --circumference: calc(var(--radius) * pi * 2);
    --dash: calc((var(--progress) * var(--circumference)) / 100);
  }

  .circular-progress circle.fg {
    transform: rotate(-90deg);
    transform-origin: var(--half-size) var(--half-size);
    stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
    transition: stroke-dasharray 2s linear 0.2s;
    stroke: #5394fd;
  }

  .circular-progress circle {
    cx: var(--half-size);
    cy: var(--half-size);
    r: var(--radius);
    stroke-width: var(--stroke-width);
    fill: none;
    stroke-linecap: round;
  }
}

@property --progress {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 700px) {
  .process {
    top: 70px;
  }
}

@media screen and (max-width: 480px) {
  .process {
    top: 70px;
  }
}
