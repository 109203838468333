.createGroup {
  display: grid;
  align-content: start;
  justify-content: center;
  z-index: 200;
  width: calc(500px - 40px);
  justify-self: center;
  min-height: 350px;
  height: calc(100vh - 22px);
  grid-template-columns: 1fr;
  grid-gap: 20px;
  border: 1px solid #ffffff17;
  padding: 10px 20px;
  color: #ffffff;
  background-color: var(--bg-color);
  user-select: none;

  input {
    height: 45px;
  }
}

.createGroupContainer {
  display: grid;
  grid-template-rows: repeat(3, auto);
  row-gap: 20px;
  align-content: start;
  color: #cacaca;
}
.createGroupHead {
  height: 40px;
  display: grid;
  grid-template-columns: 25px auto auto;
  grid-gap: 5px;
  justify-items: center;
  align-content: center;
  align-items: center;
  justify-content: start;
  color: #ffff !important;
  padding-top: 10px;

  img {
    width: 30px !important;
    height: 30px !important;
  }
  div:last-child {
    padding-left: 10px;
  }
}

.createGroupHead span {
  font-size: 20px;
  cursor: pointer;
}
.createGroupHead div {
  font-size: 20px;
  font-weight: 500;
}

input:disabled {
  color: #cacaca;
}

.groupName {
  margin-top: 10px;
  font-weight: 500;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  grid-template-rows: 25px 40px;

  .group-inputarea {
    display: grid;
    position: relative;
    input {
      padding-right: 40px;
    }
    i {
      height: 100%;
      justify-self: end;
      padding: 5px 15px;
      position: absolute;
      display: grid;
      align-content: center;
      cursor: pointer;
      transition: 0.4s ease-out;

      &:hover {
        transform: scale(1.15) rotate(270deg);
        color: #dfae31;
      }
      &:active {
        transform: scale(0.5) rotate(270deg);
      }
    }
  }
}

.generate {
  // min-width: 120px;
  padding: 12px 15px;
  justify-self: start;
  // height: 38px;
  // background-color: #1a73e8;
  border: 1px solid #393939;
  color: #fff;
  // font-weight: 400;
  letter-spacing: 0.2px;
  font-size: 14px;
  display: grid;
  align-content: center;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease 0s background-color;

  &:hover {
    background-color: #ffffff08;
    transition-delay: 0.1s;
  }
}

.groupLink {
  display: grid;
  height: 45px;
  border: 1px solid #ffffff17;
  grid-template-columns: auto 10px 20px;
  grid-gap: 10px;
  grid-template-rows: 1fr;
  align-items: center;
  padding: 0 15px;
  border-radius: 4px;
  //   min-width: 300px;
  :first-child {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.groupLink span {
  cursor: pointer;
  transition: 0.3s ease;
  padding: 10px 0;
  &:hover {
    transform: scale(1.3);
  }
  &:active {
    transform: scale(0.8);
  }
}

.border {
  border-left: 1px solid #ffffff17;
  height: 100%;
}

.results {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  row-gap: 20px;
  column-gap: 20px;
  align-items: center;
  align-content: center;
}

.openGroup {
  border: 1px solid #393939;
  text-align: center;
  border-radius: 4px;
  display: grid;
  align-content: center;
  // font-weight: 400;
  cursor: pointer;
  padding: 12px 15px;
  letter-spacing: 0.2px;
  transition: 0.3s ease 0s padding;
  justify-self: start;
}

.openGroup:hover {
  padding-left: 12%;
  transition-delay: 0.25s;
}

.group {
  &-notice {
    margin-top: 20px;
    border: 1px solid #ffffff17;
    padding: 20px;
    border-radius: 5px;
    display: grid;
    row-gap: 15px;
  }
  &-details {
    display: grid;
    align-items: center;
    align-content: center;
    grid-template-columns: repeat(2, auto);
    justify-content: start;
    column-gap: 15px;
    font-weight: 500;
    i {
      font-size: clamp(14px, 3vw, 16px);
    }
    span {
      font-size: clamp(12px, 2.1vw, 15px);
      line-height: 1.4;
    }
  }
}

@media screen and (max-width: 500px) {
  .createGroup {
    width: calc(100vw - 40px);
    border: none;
    grid-template-columns: 1fr;
  }
}
