.inboxPage {
  padding: 20px 20px;
  // margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 80px auto;
  grid-gap: 20px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  max-height: calc(100lvh - 180px);
}

.inboxCards {
  height: auto;
  min-height: 40px;
}

.myInbox {
  display: grid;
  align-content: start;
  grid-template-rows: 30px auto;
  grid-gap: 10px;
}

.myInboxHead {
  font-size: 18px;
  font-weight: 500;
}

.myInboxContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  // grid-template-rows: repeat(auto-fill, auto);
  row-gap: 25px;
  padding-bottom: 10px;
}

@media screen and (max-width: 500px) {
  .inboxPage {
    padding: 10px;
  }
}
