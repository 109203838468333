.box_container {
  display: grid;
  min-height: 500px;
  // background-color: #fff;
  padding: 35px 10px;
  color: #ffffff;
  justify-content: center;
  // grid-template-rows: 80px 30px auto 60px 1fr;
  grid-template-columns: 320px;
  grid-gap: 15px;
  padding-bottom: 50px;
  // width: ;
}

.logo {
  height: 68px;
  justify-self: center;
}

.title {
  align-self: center;
  height: 40px;
  font-weight: 100;
  font-size: 25px;
  text-align: center;
}

.form {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 15px;
  background-color: #161b22;
  border-radius: 5px;
  border: 1px solid #ffffff17;
  align-self: center;
  height: auto;
  min-height: 140px;
}

.form div {
  // background-color: #fff;
  display: grid;
  align-content: center;
  grid-template-rows: auto;
  line-height: 20px;
  row-gap: 10px;
  color: #ffffff;
}

.button {
  grid-template-rows: auto;
}

.password {
  grid-template-columns: 1fr 110px;
}

.password input {
  grid-column: span 2;
}

div {
  font-size: 14px;
  font-weight: 300;
}

input {
  height: 38px;
  background-color: var(--bg-color);
  border: 1px solid #393939;
  border-radius: 5px;
  // outline: none;
  transition: 0.01s ease-in border;
  color: #ffffff;
  padding: 0 10px;
}

input:focus,
textarea:focus {
  border-color: #1a73e8;
  outline: 1px solid #1a73e8 !important;
}

.forgot {
  justify-self: end;
  font-size: 12px;
}

.link {
  color: #1a73e8;
  cursor: pointer;
}

a {
  text-decoration: none !important;
}

.link:hover {
  text-decoration: underline;
}

.button {
  margin-top: 5px;
  justify-content: center;
  height: 38px;
  display: grid;
  align-self: last baseline;
  line-height: 30px;
  border-radius: 5px;
  background-color: #1a73e8;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
}

.button:hover {
  background-color: #1562c5;
}

.button:active {
  background-color: #1a73e8;
}

.options {
  display: grid;
  border: 1px solid #ffffff17;
  border-radius: 5px;
  align-content: center;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
  column-gap: 5px;
  font-size: 14px;
  padding: 20px;
  height: 20px;
}

.footer {
  display: grid;
  align-self: end;
  justify-self: center;
  grid-template-columns: repeat(3, auto);
  column-gap: 30px;
  height: 15px;
  padding-bottom: 20px;
}

li {
  width: 100px;
}

.error_message {
  padding: 15px;
  column-gap: 5px;
  display: grid;
  grid-template-columns: 1fr 15px;
  grid-template-rows: 1fr;
  align-content: center;
  align-items: center;
  border: 1px solid #ffffff17;
  border-radius: 5px;
  height: auto;
}

.close {
  cursor: pointer;
  align-self: center;
}

textarea {
  font-family: "onest" sans-serif;
  background: var(--bg-color);
  border: 1px solid #ffffff17;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px;
}

input[type="file"] {
  display: none;
}

.file {
  background-color: var(--bg-color);
  height: 45px;
  border: 1px solid #ffffff17;
  border-radius: 5px;
  display: grid;
  align-content: center;
  padding: 0 10px;
  cursor: pointer;
}

textarea::placeholder {
  color: #ffffffd5;
}

.terms {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 5px;
  grid-template-rows: auto;
  align-items: center;
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
  background-color: #000;
  cursor: pointer;
  outline: none !important;
}

.check_container {
  padding: 5px !important;
  transition: 0.2s ease-in all;
  cursor: pointer;
}

.check {
  color: #f5f5f5;
  font-weight: 500;
}

.check_container:hover {
  border-radius: 100%;
  background-color: #ffffff1d;
}

.check_container:active {
  background-color: #1561c5b7;
}

@media screen and (max-width: 400px) {
  .box_container {
    padding: 20px 8vw 50px;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 300px) {
  .options {
    font-size: 3.8vw;
  }
}
