.privacy {
  display: grid;
  align-content: start;
  justify-self: center;
  padding: 25px 10px;
  grid-template-columns: 1fr;
  min-width: 500px;
  width: 45vw;
  color: #ffffffa2;
  row-gap: 10px;
}

.privacy div {
  padding: 0 5px;
}

.policy_logo {
  padding: 20px 10px 40px !important;
  display: grid;
  // width: 90vw;
  grid-template-columns: repeat(3, auto);
  column-gap: 8px;
  grid-template-rows: 20px;
  justify-self: start;
  justify-items: start;
  height: 20px;
  color: #f2f2f2;
  align-self: start;
  align-content: center;
  align-items: center;
}

.policy_logo img {
  // margin-top: -15px;
  width: 30px !important;
  height: 30px !important;
}

.privacy_title {
  font-size: 20px;
  align-self: center;
  font-weight: 500;
}

.privacy_lists {
  display: grid;
  row-gap: 20px;
  line-height: 1.5;
}

.top {
  line-height: 1.5;
  font-weight: 500;
  font-size: 16px;
  color: #f2f2f2;
}

.bottom {
  padding-left: 10px !important;
  line-height: 2;
  padding-bottom: 20px !important;
}

ul {
  padding: 0 !important;
}

.dots::before {
  content: "\2022";
  font-weight: 600;
  margin-right: 8px;
  color: #f2f2f2;
}

.data {
  padding-top: 10px !important;
}

.name {
  color: #0291e3;
}

#terms_container {
  justify-content: center;
  justify-self: center;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 100%;
  min-width: 500px;
  width: 50vw;
  padding: 20px 20px 10px;
}

.terms_logo {
  justify-self: center;

  img {
    padding-top: 10px;
    width: 45px !important;
    height: 45px !important;
  }
}

.nav {
  color: #f5f5f5;
  justify-self: start;
}

header {
  color: #0291e3;
  text-align: center;
  padding-bottom: 20px;
}

h2 {
  color: #f5f5f5;
  font-weight: 400;
}

p,
li {
  color: #ffffffa2;
  font-style: normal;
  width: 95%;
  max-width: 650px;
  text-align: justify;
}

li {
  margin: 0 15px;
  width: 100%;
}

.special {
  color: #0291e3;
  font-weight: 500;
}

@media screen and (max-width: 550px) {
  .privacy {
    min-width: 98vw;
  }
  #terms_container {
    min-width: 90vw;
  }
}

@media screen and (max-width: 350px) {
  .privacy {
    overflow: auto;
  }
}
