.groupPage {
  padding: 20px;
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 1fr;
  grid-gap: 20px;
  overflow-y: auto;
}

.cards {
  height: 70px;
}

.myGroups {
  display: grid;
  align-content: start;
  grid-template-rows: 30px auto;
  grid-gap: 10px;
}

.myGroupsHead {
  font-size: 18px;
  font-weight: 500;
}

.myGroupsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  grid-template-rows: repeat(auto-fill, 70px);
  grid-gap: 20px;
}

.no-room {
  padding-top: 20px !important;
  display: flex !important;
  height: auto;
  line-height: 26px;
  width: 90%;
  color: #ffffff90;
  border: none;
  align-items: center;
  justify-self: center;
  align-self: center;
  justify-content: center;
  font-weight: 400;
  text-align: center;
  transform: scale(1) !important;
  cursor: text;
  font-size: 16px;
}

@media screen and (max-width: 500px) {
  .groupPage {
    padding: 10px;
  }
}
