@import "../../utils/_mixins";

:root {
  --land-grid-flow: row;
  --land-grid-column: 1fr;
  --land-min-h: calc(100lvh - 220px);
  --land-even-order: 0;
  --land-display-row: 80px;
  --land-img-height: 410px;
  --land-display-align: center;
  --land-display-pad: 2vw;
  --land-button-pad: 0 10px;
  --land-mask-img: linear-gradient(to bottom, #000 90%, #000 10%, transparent);
}

.landing {
  background-color: #0d1117;
  display: grid;

  &-container {
    width: 100vw;
    display: grid;
    justify-self: center;
    overflow: hidden;
    position: relative;

    &:has(.banner) {
      width: calc(100vw - (2 * (var(--land-pad) + var(--land-display-pad))));
      padding: 60px var(--land-display-pad) 50px;
      margin-top: var(--land-head-height);
      border-radius: clamp(20px, 2vw, 30px);
      background-color: #161b22;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        background-image: url(../../../public/yogrt_lite.png);
        background-size: 450px;
        background-position: center;
        opacity: 0.7;
        left: 0;
        top: 0;
      }
    }
    &:has(.landing-card) {
      &:nth-child(even) {
        & {
          background-color: #161b22;
        }
        .landing-card {
          > :first-child {
            order: var(--land-even-order);
          }
        }
      }
    }
  }

  &-display {
    justify-self: center;
    width: 100%;
    display: grid;
    grid-auto-flow: var(--land-grid-flow);
    grid-template-columns: var(--land-grid-column);
    row-gap: var(--land-display-row);
    max-width: 1540px;
    min-height: var(--land-min-h);
    max-height: 800px;
    align-self: start;
    justify-items: center;
    overflow: hidden;

    * {
      z-index: 1;
    }

    &-text {
      align-self: center;
      display: grid;
      row-gap: 14px;
      color: #ffffff;
      max-width: 350px;
      opacity: 0.9;
      justify-self: var(--land-display-align);
      text-align: var(--land-display-align);

      &.display-text {
        > * {
          opacity: 0;
        }
      }

      &.card-text {
        opacity: 0;
      }

      > :first-child {
        margin: 0;
        font-size: clamp(40px, 4.5vw, 78px);
        font-weight: 400;
        line-height: 1;
        letter-spacing: -0.1px;
      }
      > span {
        padding-top: 10px;
        font-size: 16px;
        line-height: 1.6;
        justify-self: inherit;
      }
      > :nth-child(3) {
        justify-self: inherit;
        width: 100vw;
        max-width: 120px;
        margin-top: 30px;
        height: 50px;
        padding: var(--land-button-pad);

        > :first-child {
          display: block !important;
          width: 14;
        }
      }
    }
    &-image {
      user-select: none;
      display: grid;
      height: var(--land-img-height);
      align-self: center;
      overflow: hidden;
      mask-image: var(--land-mask-img);
      img {
        // transform: scale(0.8) rotateZ(5deg);
        // box-shadow: 0 0 100px #000;
        opacity: 0;
        width: 80%;
        justify-self: center;
      }
    }
  }
  &-ribbon {
    display: grid;
    align-content: center;
    text-align: center;
    color: #ffffff;
    padding: 110px 0;
    width: 90vw;
    justify-self: center;

    h1 {
      opacity: 0;
      padding: 0 10px;
      max-width: 900px;
      justify-self: center;
      font-size: clamp(18px, 3vw, 40px);
      font-weight: 300;
      line-height: 1.5;
    }
  }
  &-card {
    margin: 0;
    row-gap: 64px;
    background-color: transparent;
    padding: 80px var(--land-display-pad);
    width: calc(100% - (2 * var(--land-display-pad)));
    border-radius: 0;

    .landing-display {
      &-text {
        align-self: center;
        max-width: 370px;
        row-gap: 15px;

        span.special {
          color: transparent;
          background: linear-gradient(to left, #5594e6, #5594e6, #0088ff);
          background-clip: text;
          -webkit-background-clip: text;
        }
        > :first-child {
          font-weight: 400;
          line-height: 1.4;
          font-size: clamp(32px, 4vw, 52px);
        }
      }
    }
  }
  &-footer {
    display: grid;
    width: 100vw;
    display: grid;
    color: #fff;
    grid-auto-flow: column;
    height: 50px;
    align-content: center;
    align-items: center;
    border: 1px solid #161b22;
    padding: 15px 0;
    border-radius: 20px 20px 0 0;
    justify-content: center;
    column-gap: 30px;

    &-links {
      display: grid;
      grid-auto-flow: column;
      column-gap: 12px;
      * {
        color: #0e83e6 !important;
      }

      a:hover {
        text-decoration: overline !important;
      }
    }
    &-btt {
      border: 1px solid #363f4b;
      padding: 4px 5px;
      border-radius: 6px;
      transition: 0.3s ease-in;
      cursor: pointer;

      &:hover {
        color: #fed400;
      }
    }
  }
}
.land-inview {
  .landing-display-text {
    --animation-name: slideup;
    @include slideYAnimation(0.3s, 0.5s);
  }
  .display-text {
    --animation-name: slideup;
    > :first-child {
      @include slideYAnimation(0.3s, 0.4s);
    }
    > span {
      @include slideYAnimation(0.3s, 0.4s);
    }
    > :last-child {
      @include slideYAnimation(0.5s, 0.4s);
    }
  }
  .landing-display-image {
    --animation-name: img-slideup;
    > img {
      @include slideYAnimation(0.8s, 0.6s);
    }
  }
  &.landing-ribbon {
    --animation-name: slideup-excess;
    h1 {
      @include slideYAnimation(0s, 0.7s);
    }
  }
}

.landing-card.land-inview {
  .card-text {
    --animation-name: slideup;
    @include slideYAnimation(0.2s, 0.85s);
  }
  .landing-display-image {
    --animation-name: img-slideup-alt;
    > img {
      @include slideYAnimation(0.7s, 0.7s);
    }
  }
}

@keyframes slideup {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes slideup-excess {
  from {
    opacity: 0;
    transform: translateY(80px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes img-slideup {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes img-slideup-alt {
  0% {
    opacity: 0;
    transform: translateY(30%);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media screen and (min-width: 840px) {
  :root {
    --land-grid-flow: column;
    --land-grid-column: auto auto;
    --land-min-h: clamp(0px, calc(100lvh - 180px), 700px);
    --land-even-order: 1;
    --land-display-row: 100px;
    --land-img-height: 460px;
    --land-display-align: auto;
    --land-display-pad: 40px;
    --land-button-pad: 0 20px;
    --land-mask-img: linear-gradient(
      to bottom,
      #000,
      #000,
      #000,
      #000,
      transparent
    );
  }
  .landing-card.land-inview {
    .card-text {
      --animation-name: slideup-excess;
      @include slideYAnimation(0.6s, 0.5s);
    }
    .landing-display-image {
      --animation-name: img-slideup-alt;
      > img {
        @include slideYAnimation(0s, 0.8s);
      }
    }
  }
}
