.chat {
  &-options {
    position: fixed;
    width: 100vw;
    z-index: 1000;
    bottom: 0;
    color: #fff;
    display: grid;
    max-height: 100vh;
    grid-template-rows: 100vh auto;
    overflow: auto;
    scroll-snap-type: y mandatory;
    -webkit-overflow-scrolling: touch;
    transition: 0.4s ease;
    &::-webkit-scrollbar {
      display: none;
    }
    div {
      scroll-snap-align: start;
    }

    &-holder {
      padding: 6px 0 12px;
      display: grid;
      justify-content: center;

      i {
        height: 4px;
        background-color: #fff;
        width: 40px;
        border-radius: 10rem;
        cursor: pointer;
      }
    }

    &-null {
      background-color: #0d1117;
      opacity: 0.25;
    }

    &-container {
      border-radius: 20px 20px 0 0;
      overflow: hidden;
      background-color: #1a1f24;
      display: grid;
      padding: 10px 0;

      > div {
        height: 40px;
        padding: 12px 0 12px 20px;
        display: grid;
        align-content: center;
        align-items: center;
        justify-content: start;
        grid-auto-flow: column;
        column-gap: 10px;
        font-weight: 500;
        letter-spacing: 0.8px;
        background-color: #1a1f24;
        cursor: pointer;

        &:hover {
          filter: contrast(1.08);
        }

        > i {
          background-color: #2b3543;
          padding: 10px;
          border-radius: 50%;
        }
      }
    }
  }
}
