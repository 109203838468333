.inboxCards {
  display: grid;
  border: 1px solid #ffffff17;
  grid-template-rows: 30px auto;
  row-gap: 15px;
  padding: 10px 20px 15px;
  position: relative;
}

.slide-cards {
  transition: 1s ease;
  height: calc(100% - 20px);
}

.unread {
  font-weight: 600;
}

.unread_date {
  font-size: 11px;
  color: #fff;
  font-weight: 600;
}

.inbox_card_head {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 30px 1fr auto 20px;
  align-content: center;
  column-gap: 20px;
  align-items: center;
}

.inbox_msg_container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 94%;
}

.inbox_icon {
  height: 30px;
  width: 30px;
  //   background-color: #1a73e8;
  color: #caa801;
  font-size: 20px;
  border-radius: 100%;
  display: grid;
  align-content: center;
  justify-content: center;
}

.inbox_type {
  display: grid;
  font-size: 16px;
  font-weight: 500;
  row-gap: 3px;
}

.inbox_date {
  font-size: 12px;
  color: #ffffff88;
}

.half_inboxMsg {
  color: #ffffffaf;
  height: 20px;
  overflow: hidden;
}

.full_inboxMsg {
  color: #ffffffe2;
  line-height: 1.5;
  height: auto;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  width: 100%;
  font-weight: 400;
  font-size: 15px;
}

.rotate {
  transform: rotate(180deg);
}

.drop {
  cursor: pointer;
  font-size: 15px !important;
  color: #fff8d4;
}

.fas {
  transition: 0.2s ease-out;
}

.inbox-capture {
  display: grid;
  right: 15px;
  top: 5px;
  justify-items: start;
  height: 40px;
  padding-top: 10px;
  align-items: center;
  color: #fed400;
  font-size: 16px;
  margin-top: -10px;
  i {
    border-radius: 50%;
    padding: 15px;
    position: relative;
    display: grid;
    overflow: hidden;
    justify-items: center;
    align-items: center;

    &::before {
      cursor: pointer;
      position: absolute;
      z-index: 10;
      padding: 12px;
    }

    &::after {
      content: "";
      padding: 40px;
      align-self: center;
      justify-self: center;
      border-radius: 50%;
      position: absolute;
      z-index: 0;
      transition: 0.5s ease !important;
    }

    &:active:after {
      background-color: #ffffff60;
      padding: 0px;
      transition: 0s ease !important;
    }

    &:hover {
      background-color: #ffffff20;
    }
  }
}

@media screen and (max-width: 480px) {
  .inbox-capture {
    i {
      &::before {
        position: absolute;
        z-index: 10;
      }

      &::after {
        transition: 0.2s ease !important;
      }
    }
  }
}
