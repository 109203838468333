.cards {
  display: grid;
  column-gap: 20px;
  row-gap: 5px;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: repeat(2, 1fr);
  // background-color: #0088ff;
  border: 1px solid #ffffff17;
  border-radius: 0.3rem;
  padding: 5px 10px;
  cursor: pointer;
  transition: 0.3s ease-in border;
  position: relative;
}

.cards:hover {
  // transform: scale(1.02);
  border-color: #ffffff30;
}

.card_icon {
  grid-column: 1/3;
  grid-row: span 2;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
}

.icon {
  border-radius: 100%;
  background-color: #fed400;
  width: 50px;
  height: 50px;
  justify-self: center;
  align-self: center;
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: #000;
}

.card_title {
  grid-column: 3/12;
  grid-row: 1/2;
  align-self: end;
  font-weight: 500;
}

.card_desc {
  grid-column: 3/12;
  grid-row: 2/3;
  font-size: 11px;
  align-self: start;
  font-weight: 400;
  color: #b7b7b7;

  &::first-letter {
    text-transform: uppercase;
  }
}

.copy_link {
  grid-column: 9/11;
}

.cards div {
  // background-color: red;
}

.card_options {
  position: absolute;
  right: 2%;
  padding: 0 20px;
  align-self: center;
  height: 100%;
  z-index: 100;
  color: #fed400;
  background: linear-gradient(
    to right,
    transparent,
    var(--bg-color),
    var(--bg-color),
    var(--bg-color)
  );
  display: grid;
  grid-auto-flow: column;
  column-gap: 30%;
  align-content: center;
  .material-symbols-rounded {
    font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 20;
    transition: 0.25s ease-out;

    &:hover {
      transform: scale(1);
    }
    &:active {
      transform: scale(0.8);
    }
  }
}

@media screen and (min-width: 650px) {
  .cards {
    padding: 5px 20px;
  }
}
