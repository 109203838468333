:root {
  --blog-column: 300px 1fr;
  --blog-row: 1fr;
  --blog-img-min-height: 200px;
}

.blog {
  display: grid;
  color: #fff;
  padding-top: 100px;
  min-height: calc(100lvh - 120px);

  &-container {
    justify-self: center;
    max-width: 1140px;
    padding: 0 clamp(10px, 5vw, 80px);
    display: grid;
    padding-top: 40px;
    align-content: start;

    > :nth-child(2) {
      max-width: 895px;
      min-height: 400px;
      grid-template-rows: repeat(2, auto);
      grid-template-columns: 1fr;
      margin: 20px 0 100px;
      --blog-img-min-height: 300px;
      // max-height: 700px;
      // max-height: 500px;

      img {
        &:hover {
          transform: scale(1);
        }
      }
      :nth-child(1) {
        font-size: 18px;
      }
      :nth-child(2) {
        font-size: clamp(30px, 3.5vw, 40px);
      }
    }
  }
  &-title {
    justify-self: center;
    font-weight: 400;
    font-size: clamp(42px, 4.5vw, 68px);
    padding: 15px 0 50px;
  }

  &-empty {
    padding: 10px 20px;
    border: 1px solid #434c5890;
    border-radius: 8px;
    justify-self: center;
    display: grid;
    align-content: center;
    justify-content: center;
    width: calc(85vw - 40px);
    max-width: 500px;
    transition: 0.2s ease-in;
    font-size: 20px;
    line-height: 1.5;
    font-weight: 300;
    opacity: 0.9;
    text-align: center;

    &:hover {
      border-color: #434c58;
    }
  }

  > :last-child {
    align-self: last baseline;
  }

  &-card {
    padding: 20px;
    border: 1px solid #434c5890;
    border-radius: 8px;
    justify-self: center;
    align-content: start;
    display: grid;
    max-width: 780px;
    grid-template-columns: var(--blog-column);
    grid-template-rows: var(--blog-row);
    grid-gap: 30px;
    margin-bottom: 40px;

    &-date {
      opacity: 0.7;
    }

    &-title {
      font-size: clamp(26px, 3.5vw, 36px);
      font-weight: 300;
      padding: 10px 0;
    }
    &-short_desc {
      font-size: 16px;
      word-spacing: 1.5px;
      opacity: 0.8;
    }
  }
  &-image {
    display: grid;
    // background-color: #ffffff08;
    border-radius: 6px;
    min-width: 200px;
    border: 1px solid #434c5890;
    align-content: center;
    overflow: hidden;

    // min-height: var(--blog-img-min-height);

    img {
      width: calc(100% - 2px);
      transition: 0.3s ease-in;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
  &-content {
    display: grid;
    padding: 6px 0;
    align-content: space-between;
    &-action {
      margin-top: 24px;
      color: #0e83e6;
      cursor: pointer;
      justify-self: start;
    }
  }
}

@media screen and (max-width: 720px) {
  :root {
    --blog-column: 1fr;
    --blog-row: auto auto;
    --blog-img-min-height: 250px;
  }
}
