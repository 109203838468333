.settings {
  width: 100vw;
  max-width: 500px;
  justify-self: center;
  border: 1px solid #ffffff17;
  display: grid;
  grid-template-rows: 60px auto;
  background-color: #0d1117;
  height: calc(100vh - 2px);
  user-select: none;
}

.settingsHead {
  padding: 10px 20px;
  color: #fff;
  background-color: #161b22;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, auto);
  justify-content: start;
  column-gap: 20px;
  grid-template-rows: 1fr;
}

.settingsTitle {
  font-size: 20px;
  font-weight: 500;
}

.settingsContainer {
  display: grid;
  padding: 20px;
  row-gap: 20px;
  color: #fff;
  align-content: start;
  div {
    :nth-child(2) {
      text-transform: capitalize;
    }
  }
}

.settingsContainer .card_title {
  font-size: 18px;
  margin-top: 10px;
  font-weight: 00;
}

.settingsContainer .cards:nth-child(2) .card_icon span {
  font-size: 20px;
}

.settingsContainer .card_icon span {
  background-color: transparent !important;
  color: #ffffffc2;
  font-size: 25px;
}

.delete_acc {
  border-color: #ff2b2b46;
  color: #ffa6a6;
  span {
    color: #ffa6a6 !important;
  }
  //   color: red;
}

.confirm-delete {
  &-container {
    display: grid;
    position: fixed;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(4px);
    left: 0;
    top: 0;
    z-index: 100;
    animation: pop 0.3s linear;
  }
  width: calc(85vw - 40px);
  max-width: 350px;
  padding: 20px;
  // height: 200px;
  max-height: clamp(100px, 80lvh, 600px);
  overflow: auto;

  &::-webkit-scrollbar {
    display: block;
  }

  align-self: center;
  justify-self: center;
  margin-top: clamp(-100vw, -1vw, 0px);
  background-color: #0d1117;
  border: 3px solid #ffffff25;
  border-radius: 10px;
  display: grid;
  row-gap: 30px;
  &-confirm {
    color: #fff;
    text-align: center;
    padding-top: 10px;
    font-size: 15px;
    font-weight: 500;
  }
  &-title {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    color: #ffffffcd;
  }
  .error_message {
    color: #fff;
  }
  &-body {
    display: grid;
    grid-template-rows: repeat(2, auto);
    align-content: start;
    row-gap: 10px;
    color: #fff;
    .error_message {
      padding: 12px;
      margin-bottom: 12px;
    }
  }
  &-btn {
    display: grid;
    grid-template-columns: repeat(2, auto);
    text-align: center;
    column-gap: 10%;
    height: 40px;

    div {
      display: grid;
      align-items: center;
      border-radius: 4px;
      font-weight: 500;
      font-size: 15px;
      cursor: pointer;
      transition: 0.2s ease;
    }
    :first-child {
      background-color: #fff;
    }
    :last-child {
      background-color: #ffa6a6;
      cursor: not-allowed;
    }
  }
}
.confirm-delete {
  &.for-logout {
    height: 100px;
    max-width: 290px;
    .confirm-delete {
      &-btn {
        height: 35px;
        column-gap: 13%;
        padding: 0 20px;
      }
    }
  }
}

.confirm-logout {
  background-color: #004bab !important;
  cursor: pointer !important;
  color: #fff !important;
  &:hover {
    background-color: #0054c1 !important;
  }
}

.delete_confirm {
  background-color: #ff2c2c !important;
  color: #fff !important;
  cursor: pointer !important;
}

.update {
  background-color: #83b8ff !important;

  &-confirm {
    cursor: pointer !important;
    background-color: #0054c1 !important;
    color: #fff;
  }
}
@keyframes pop {
  0% {
    transform: scale(0.4);
  }
  60% {
    transform: scale(1.15);
  }
}

@media screen and (max-width: 550px) {
  .settings {
    border: none;
    height: 100vh;
  }

  .card_desc,
  .card_title {
    margin-left: 10px;
  }
}
