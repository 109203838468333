.compose {
  padding: 30px 20px;
  justify-self: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 320px;
  row-gap: 10px;
  grid-template-rows: 100px auto auto;
}

.compose_logo {
  justify-self: center;
}

.compose_title {
  color: #fff;
  font-size: 25px;
  font-weight: 200 !important;
  text-align: center;
  height: auto;
  word-break: break-word;

  strong {
    text-transform: capitalize;
    font-weight: 400 !important;
  }
}

.compose_container {
  border-radius: 4px;
  border: 1px solid #ffffff17;
  height: auto;
  background-color: #161b22;
  display: grid;
  grid-template-rows: auto 50px;
  padding: 20px;
}

#composeText {
  resize: vertical;
  min-height: 100px;
  max-height: 400px;
}

.close {
  color: #fff;
}

.button {
  color: #fff;
  line-height: 2.3;
}

.workContainer {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  display: grid;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(8px);
  grid-template-rows: 200px;
  grid-template-columns: 320px;
  justify-content: center;
  align-content: center;
}

.works {
  // margin-top: calc(50vh - 100px);
  background-color: #0d1117;
  border: 4px solid #ffffff17;
  border-radius: 10px;
  padding: 10px;
  display: grid;
  grid-template-rows: 25px auto;
  row-gap: 15px;
  animation: bounce 0.4s linear;
  // background-color: #161b22;
}

@keyframes bounce {
  0% {
    transform: scale(0.2);
  }
  50% {
    transform: scale(1.2);
  }
  90% {
    transform: scale(1);
  }
}

.head {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
}

.pop_head {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.work_lists {
  display: grid;
  padding: 0 10px;
}

.work_lists div {
  color: #ffffffd3;
  font-weight: 500;
}

.work_lists div::before {
  content: "•";
  margin-right: 6px;
  font-weight: 600;
  color: #fed400;
}

.head span {
  // width: 20px;
  justify-self: end !important;
  position: absolute;
  font-size: 16px;
  transition: 0.1s ease-in transform !important;
  color: #fff;
  cursor: pointer;
  padding: 1px;
}

.head span:hover {
  transform: scale(1.3);
}
.head span:active {
  transform: scale(0.8);
}

.hide_pop {
  animation: reverse_bounce 0.3s linear;
  transform: scale(0);
}

@keyframes reverse_bounce {
  100% {
    display: none;
    transform: scale(0);
  }
  30% {
    opacity: 0.5;
    transform: scale(1.2);
  }
  0% {
    transform: scale(1);
  }
}

@media screen and (max-width: 500px) {
  .compose {
    padding: 30px 8vw;
    grid-template-columns: 1fr;
  }
  .compose_container {
    background-color: var(--bg-color);
  }
}
