.homePage {
  padding: 20px 20px 50px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  grid-template-rows: repeat(auto-fill, 70px);
  grid-gap: 20px;
  overflow-y: auto;
  padding-bottom: 100px !important;
}

.share {
  border: 1px solid #ffffff17;
  height: auto;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-around;
  align-content: center;
  //   justify-items: center;
  column-gap: 20px;
  border-radius: 4px;
}

.share a {
  display: grid;
  justify-content: center;
  border: 1px solid #ffffff17;
  border-radius: 4px;
  padding: 10px 0px;
  cursor: pointer;
  transition: 0.2s ease;
}

.share a:hover {
  //   border: 1px solid #ffffff47;
  transform: scale(1.1);
}

.share a span {
  font-size: 22px;
  color: #fff;
}

@media screen and (max-width: 500px) {
  .homePage {
    padding: 10px;
  }
}
