body:has(.index-container) {
  height: 100vh !important;
  background-color: var(--xbg-color);
  margin: 0;
  padding: 0;

  &::-webkit-scrollbar-thumb {
    background: #7e7e7e;
  }

  &::-webkit-scrollbar-track {
    background: var(--xbg-color);
  }
}
.container:has(.index-container) {
  padding: 0;
  margin: 0;
  max-height: none;
  height: 100%;
}
:root:has(.index-container) {
  --gradient: linear-gradient(
    to left,
    var(--secondary),
    var(--secondary),
    var(--secondary),
    var(--grad-alt),
    var(--grad-alt)
  );
  --xbg-color: #ffffff;
  --title: #111111;
  --grad-alt: #6d0cd4;
  --secondary: #1a73e8;
  --trans: #ffffffa7;
  transition: 0.2s ease background !important;
}
:root:has(.index-container.darkmode) {
  transition: 0s ease background !important;
  --gradient: var(--secondary);
  --secondary: #fed400;
  --xbg-color: #161b22;
  --title: #ececec;
  --trans: #161b22a7;

  * ::selection {
    color: #0d1117;
    background-color: #ffffff;
  }
}
:root {
  --mask-image: linear-gradient(
    to right,
    transparent,
    #000000,
    #000000,
    #000000,
    #000000,
    #000000,
    #000000,
    #000000,
    transparent
  );
}
#toggle-mode {
  box-shadow: 0 0 15px #00000040;
  background-color: var(--xbg-color);
  border: 2.5px solid #ffffff;
  padding: 5px;
  width: 30px;
  height: 30px;
  display: grid;
  align-content: center;
  justify-content: center;
  color: var(--title);
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  right: 25px;
  bottom: 25px;
  // span {
  transition: 0.4s ease-in transform;

  &:active {
    transform: rotate(180deg);
  }
  // }
}

.index {
  &-container {
    display: grid;
    background-color: var(--xbg-color);
    display: grid;
    grid-template-rows: repeat(3, auto);
    align-content: space-evenly;
    padding: 120px 0 10px;
    min-height: calc(100% - 95px);
  }
  &-header {
    top: 0;
    left: 0;
    background-color: var(--trans);
    backdrop-filter: blur(10px);
    justify-self: center;
    position: fixed;
    max-width: 90vw;
    width: calc(100% - (10vw + 6px));
    display: grid;
    align-content: center;
    padding: 20px 5vw;
    grid-template-columns: auto 1fr auto;
    justify-content: start;
    color: var(--title);
    z-index: 100;
    font-size: 16px;
    font-weight: 500;
    img {
      width: 35px !important;
      height: 35px !important;
    }
    &-title {
      font-weight: inherit;
      line-height: 2;
      overflow: hidden;
      font-size: inherit;
      white-space: nowrap;
      padding-left: 10px;
    }
  }
  &-select {
    font-weight: inherit;
    font-size: inherit;
    max-height: 40px;
    justify-self: end;
    border-radius: 5px;
    display: grid;
    align-content: center;
    align-items: center;
    justify-content: center;
    min-width: 135px;
    width: 26vw;
    max-width: 150px;
    grid-template-columns: repeat(2, auto);
    justify-content: space-evenly;
    cursor: pointer;

    i {
      transition: 0.3s ease;
    }
    .rotate {
      transform: rotateX(180deg);
    }
  }
  &-options {
    user-select: none;
    position: absolute;
    background-color: var(--xbg-color);
    border: 2px solid #ffffff80;
    box-shadow: 0 0 20px #11111120;
    height: 280px;
    border-radius: 6px;
    max-width: 190px;
    width: 25vw;
    min-width: 170px;
    top: 70px;
    right: 5vw;
    row-gap: 2px;
    display: none;
    overflow: hidden;

    div {
      transition: 0.2s ease-in-out background;
      display: grid;
      align-content: center;
      font-size: 15px;
      font-weight: 400;
      padding: 0 20px;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background-color: #72727220;
      }
    }
  }
  &-body {
    max-width: 600px;
    display: grid;
    align-content: center;
    width: calc(100% - 2 * (5vw + 6.5px));
    grid-template-columns: repeat(5, 100%);
    mask-image: var(--mask-image);
    mask-position: fixed;
    padding: 0 calc(5vw + 6.5px);
    // column-gap: 20px;
    column-gap: clamp(62px, 10vw, 160px);
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-padding: calc(5vw + 6.5px);
    -webkit-overflow-scrolling: touch;
    div {
      scroll-snap-align: start;
      scroll-snap-stop: always;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-image {
    min-height: 400px;
    width: calc(100% - (5vw + 5px));
    margin-top: 40px;

    div {
      :first-child {
        transition: 1s ease;
        // animation: pop-tab 15s ease-out infinite;
        // animation: show;
      }
      :last-child {
        position: absolute;
        transition: 0.3s ease;
        bottom: -5%;
        opacity: 0;
        right: -4.5%;
        width: 60% !important;
        animation: pop 15s ease-out infinite;
      }
    }
  }
  &-footer {
    padding-top: 50px;
    height: auto;
    align-self: last baseline;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(20px, auto));
    justify-content: start;
    align-items: center;
    align-content: center;
    padding-left: calc(5vw + 5px);
    span,
    a {
      color: var(--title);
      transition: 0.2s ease-out;
      justify-self: start;
      cursor: pointer;
    }
    a {
      cursor: text !important;
      padding: 0 clamp(5px, 2.5vw, 20px);
      font-weight: 500;
      font-size: clamp(13px, 3vw, 16px);
    }
    a span {
      opacity: 0.85;
      &:hover {
        text-decoration: overline !important;
        opacity: 1;
      }
    }
  }
}

@keyframes pop {
  1% {
    opacity: 0;
  }
  2% {
    bottom: -15%;
    opacity: 0;
  }
  3% {
    opacity: 1;
  }
  8% {
    bottom: 0;
    opacity: 1;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes pop-tab {
  0% {
    transform: translateX(-30.5%);
  }
  5% {
    opacity: 1;
    transform: translateX(-10%);
  }
  100% {
    opacity: 1;
  }
}

#screenshot {
  position: relative;
  display: none;
  align-items: center;
  justify-items: center;

  img {
    user-select: none;
    width: 85%;
  }
}
#screenshot.show-phone {
  display: grid !important;

  :last-child {
    transform: translateX(15%) translateY(-5%) !important;
  }
}

.tab {
  opacity: 0;
  display: grid;
  justify-content: space-around;
  justify-self: center;
  height: 90%;
  &-icon {
    font-size: 28px;
    justify-self: start;
    display: grid;
    align-content: center;
    justify-content: center;
    background: var(--gradient);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }
  &-title {
    color: var(--title);
    font-size: 32px;
    font-weight: 400;
    padding-top: 15px;

    span:nth-child(1) {
      background: var(--gradient);
      color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      display: inline-block;
      font-weight: inherit;
    }
  }
  &-body {
    padding-top: 10px;
    color: var(--title);
    opacity: 0.8;
    font-size: 17px;
    line-height: 1.5;
    font-weight: 400;
  }
  &-button {
    margin-top: 30px;
    border: 2px solid var(--secondary);
    background-clip: text;
    -webkit-background-clip: text;
    width: 160px;
    height: 42px;
    display: grid;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.6px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    color: var(--secondary);
    &::before {
      content: "Next";
      z-index: 1;
      position: absolute;
      align-self: center;
      justify-self: center;
    }
    &::after {
      color: var(--title);
      transition: 0.25s ease-out;
      content: "";
      background-color: var(--secondary);
      width: 0%;
      height: 100%;
      justify-self: end;
    }
    &:hover::after {
      width: 100%;
      justify-self: start;
    }
    &:hover::before {
      color: var(--xbg-color);
      font-weight: 600;
    }
  }
}

.getStarted {
  color: var(--xbg-color);
  &::before {
    content: "Get Started";
    align-self: center;
  }
  &::after {
    width: 100%;
  }
}

.slide {
  animation: slide-x 0.7s linear forwards;
}

.drop-index {
  opacity: 1;
  display: grid !important;
  animation: slide-in 0.2s linear forwards;
}

@keyframes slide-in {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-x {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 600px) {
  .index {
    &-header {
      img {
        width: 40px !important;
        height: 40px !important;
      }
    }
    &-footer {
      .material-symbols-outlined {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  body:has(.index-container) {
    height: 100% !important;
  }
  .index {
    &-header {
      &-title {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 860px) {
  .container:has(.index-container) {
    // max-height: 100vh;
  }
  .index {
    &-container {
      padding-top: 30px !important;
      grid-template-rows: 1fr auto;
      grid-template-columns: 2fr 3fr;
      align-items: center;
      height: calc(100vh - 40px);
      min-height: 700px;
    }
    &-body {
      width: 85%;
      max-width: none !important;
      padding-bottom: 50px;
    }
    &-image {
      padding-bottom: 50px;
      justify-self: start;
    }
    &-footer {
      padding-top: 0px;
    }
  }
  .tab {
    &-title {
      font-size: 32px;
    }
  }
}

@media screen and (min-width: 1440px) {
  .index {
    &-header {
      font-size: 18px;
      img {
        width: 40px !important;
        height: 40px !important;
      }
      &-title {
        padding-left: 20px;
        line-height: 2.2;
      }
    }
    &-select {
      max-width: 170px !important;
    }
    &-body {
      width: calc(88% - (5vw));
    }
    &-image {
      justify-self: start;
    }
  }
  .tab {
    &-title {
      font-size: 2.2vw !important;
    }
    &-body {
      font-size: 20px;
      font-weight: 400;
    }
  }
}

@media screen and (min-width: 2560px) {
  .index {
    &-container {
      padding: 20px 8vw 50px;
    }
    &-body {
      width: calc(90% - (5vw));
    }
    &-header {
      width: calc(100% - (16vw + 6.5px));
      padding: 20px 8vw;
    }
  }
  .tab {
    &-body {
      width: 80%;
    }
    &-icon {
      font-size: 1.5vw !important;
    }
    &-button {
      width: 220px;
      height: 50px;
      font-size: 20px;
    }
  }
  #toggle-mode {
    transform: scale(2.2);
    right: 2vw;
    bottom: 2vw;
  }
}

@media screen and (max-width: 920px) {
  :root {
    --mask-image: linear-gradient(
      to right,
      transparent,
      #000000,
      #000000,
      #000000,
      #000000,
      #000000,
      #000000,
      #000000,
      #000000,
      #000000,
      #000000,
      #000000,
      #000000,
      transparent
    );
  }
}
