.homepage {
  display: grid;
  color: #ffffff;
  justify-content: start;
  height: calc(100lvh - 2px);
  grid-template-rows: 120px auto;
  grid-template-columns: 500px;
  justify-self: center;
  border-right: 1px solid #ffffff17;
  border-left: 1px solid #ffffff17;
  border-bottom: 1px solid #ffffff17;
  overflow: hidden;
  user-select: none;
  background-color: var(--bg-color);
  margin-bottom: 100px;
}

.homeHeader {
  display: grid;
  background-color: #161b22;
  grid-template-rows: 1fr 40px;
  grid-template-columns: 1fr;
  padding: 10px 20px;
  border: 1px solid #ffffff17;
}

.homeTitle {
  display: grid;
  grid-template-columns: 1fr 20px;
  align-items: center;
  align-content: center;
}

.homeTitle a {
  font-size: 20px;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #fff;
}

.appName {
  text-align: left;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.05rem;
  font-family: "Pacifico", cursive;
}

.menu {
  // background-color: #fff;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  justify-items: center;
  align-self: center;
}

.menuItems {
  display: grid;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 0.07rem;
  transition: 0.1s ease-in all;
  color: #c7c7c7;
  align-items: center;
  font-weight: 700;
  display: grid;
  grid-template-columns: repeat(2, auto);
  overflow: hidden;
  transition: 0.3s ease;

  span {
    margin-left: 7px;
    background-color: #c7c7c7;
    color: var(--bg-color);
    padding: 1.5px 8px 0.3px;
    border-radius: 50rem;
    font-size: 12px;
    font-weight: 700;
    display: grid;
    align-content: center;
  }
}

.current_page {
  color: #fed400;

  span {
    background-color: #fed400;
  }
}

.homeContainer {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 500px);
  overflow-y: hidden;
  overflow-x: auto;
  color: #fff !important;
  align-content: center;
  position: relative;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  transition: 0.4s ease;
  &::-webkit-scrollbar {
    display: none;
  }
  > div {
    scroll-snap-align: start;
    scroll-snap-stop: always;

    // &:nth-child(2) {
    // }
  }
}

.footer {
  position: fixed;
  width: 500px;
  line-height: 35px;
  justify-content: center;
  bottom: 0px;
  background-color: var(--bg-color);
  z-index: 20;
}

@media screen and (max-width: 550px) {
  .footer {
    width: 90vw;
  }
  .homeHeader {
    border-width: 0 0 1px;
  }
}

@media screen and (max-width: 500px) {
  .homepage {
    // max-height: 87vh;
    grid-template-columns: 100vw;
    // justify-self: center;
    border: none;
  }
  .homeContainer {
    grid-template-columns: repeat(3, 100vw) !important;
  }
}

.homePage,
.inboxPage,
.groupPage {
  overflow-y: hidden;
}
