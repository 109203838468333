.send {
  width: auto;
  justify-self: end;
  display: grid;
  grid-template-columns: 94px auto;
  grid-template-rows: auto;
  align-items: center;
  align-content: center;
  align-self: last baseline;
  max-width: clamp(0px, 90%, 500px);
  padding: 0 10px;
  padding-right: clamp(10px, 3vw, 25px);
  scroll-snap-align: end;

  &:has(.image-msg) {
    min-width: clamp(0px, 90%, 500px);
  }

  &:hover .reply {
    > span {
      transform: scale(1);
    }
  }

  &-container {
    transition: 0.3s ease;
    scroll-snap-type: x mandatory;
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    display: grid;
    margin-bottom: 15px;
    grid-template-columns: 100% 100px;

    .reply_alt {
      scroll-snap-align: none;
      align-self: center;
      justify-self: center;
      padding-left: 10px;
      display: grid;
      transform: scale(0);
      color: #0291e3 !important;
      span {
        justify-self: end !important;
        position: sticky;
        left: 10px;
        right: 10px;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    :last-child {
      span {
        text-align: center;
      }
    }
  }
}
.sentMsg {
  box-shadow: 0 0 1px 1px #0d1117;
  margin: 1px;
  border-radius: 12px;
  border-bottom-right-radius: 0;
  display: grid;
  height: auto;
  justify-content: end;
  background-color: #005fda;
  align-items: center;
  align-content: center;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 1fr;
  padding: 10px 10px 6px;
  // min-width: 80px;
}

.time {
  color: #ffffffbf;
  font-size: 10px;
  transform: translateY(-5px);
  line-height: 5px;
  justify-self: end;
  font-weight: 500;
}

.reply {
  color: #ffffff;
  opacity: 0.8;
  text-align: right;
  padding-right: 10px;
  display: grid;
  grid-auto-flow: column;
  transition: 0.2s ease;

  > span {
    transition: 0.1s ease-in transform;
    padding: 10px;
    justify-self: center;
    border-radius: 50%;
    transform: scale(0);
    cursor: pointer;

    &:first-child {
      transition-delay: 0.15s;
    }
    &:last-child {
      transition-delay: 0.1s;
    }

    &:hover {
      background-color: #434c58;
    }
  }
}

.message {
  word-break: break-word;
  white-space: pre-wrap;
  border-radius: 30rem;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  padding-right: 55px;
  &-img {
    height: 100%;
    img {
      height: 100%;
    }
  }
}

.replied {
  display: grid;
  grid-auto-flow: column;
  padding: 5px 6px 5px 7px;
  background-color: #00000037;
  border-left: 3.5px solid #161b22;
  border-radius: 4px;
  color: #ffffffbf;
  min-width: clamp(0px, 40vw, 120px);
  position: relative;
  margin-bottom: 10px;
  align-items: center;
  justify-content: start;
  column-gap: 10px;

  &:has(.reply-image-thumb) {
    > :last-child {
      row-gap: 0;
      font-weight: 400;
    }
  }

  &-msg {
    height: auto;
    line-height: 1.5;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: inherit;
    max-width: 100%;
  }

  > :last-child {
    display: grid;
    grid-template-rows: repeat(2, auto);
    align-content: space-between;
    padding: 5px 0 0;
    row-gap: 4px;
  }

  .reply-image-thumb {
    display: grid;
    overflow: hidden;
    width: 40px;
    height: 40px;
    padding: 0;
    place-content: center;
    border-radius: 2px;
    box-shadow: -0.3px 0.3px 0.8px 0.8px #0d1117d0;
    background-color: #161b22;
    img {
      width: 100%;
      min-height: 40px;
      object-fit: cover;
    }
  }

  &-thumb {
    color: #fff;
    font-size: 16px;
    margin-left: -2px;
  }

  &::after {
    height: 10px;
    line-height: 0.8;
    position: absolute;
    font-size: 18px;
    padding-left: 5px;
    font-weight: 500;
    top: 92px;
    padding-bottom: 12px;
    background-color: #004bab;
    width: 100%;
    left: 0;
    // content: "...";
  }
}

.image-msg {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0;
  border: 4px solid #005fda;
  position: relative;
  overflow: hidden;

  .replied,
  .receive_replied {
    margin: 4px 4px 8px;
  }
  .message {
    margin: 8px 0 0;
    padding-left: 6px;
    &-img {
      display: grid;
      max-height: 260px;
      overflow: hidden;
      place-items: center;
      max-width: 100%;
      background-color: #000;
      border-radius: 0 0 5px 5px;
      img {
        width: 100%;
        min-height: 70px;
        object-fit: cover;
      }
    }
  }
  .time {
    margin-right: 6px;
  }
  &.no-caption {
    padding: 0 !important;
    .message {
      background-color: #000;
      &-img {
        border-radius: 2px;
        img {
        }
      }
    }
    .time {
      position: absolute;
      bottom: 10px;
      right: 0;
      margin: 5px;
      background-color: #0d111750;
      color: #fff;
      padding: 8px 10px;
      font-weight: 600;
      border-radius: 10rem;
      font-size: 11px;
      transform: none;
      // background: linear-gradient(-25deg, #0d1117, #0d1117, transparent);
    }
  }
}

@-moz-document url-prefix() {
  .send,
  .receive {
    &-container {
      // display: none;
      overflow-x: hidden !important;
    }
  }
}
