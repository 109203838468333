.about {
  display: grid;
  color: #fff;
  max-width: 1240px;
  justify-content: start;
  justify-self: center;
  padding: 10px clamp(10px, 5vw, 80px);
  padding-top: 120px;

  &-card {
    margin: 25px 0;
    padding: 30px 20px 40px;
    border: 1px solid #434c5890;
    border-radius: 8px;
    justify-self: center;
    display: grid;
    row-gap: 20px;
    max-width: 750px;
    transition: 0.2s ease-in;

    &:hover {
      border-color: #434c58;
    }
  }

  &-title {
    font-weight: 400;
    font-size: clamp(32px, 4vw, 58px);
  }
  &-head {
    font-weight: 300;
    font-size: clamp(24px, 2vw, 38px);
    // line-height: 2.4;
  }
  &-body {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    opacity: 0.9;
  }
}
