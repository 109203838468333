@mixin slideYAnimation($delay, $duration) {
  opacity: 0;
  animation: var(--animation-name) $duration
    cubic-bezier(0.175, 0.135, 0.105, 0.71) $delay forwards !important;

  @media (prefers-reduced-motion: reduced) {
    animation-duration: 0s !important;
    animation-delay: 0s !important;
  }
}
