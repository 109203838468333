:root {
  --bg-color: #0d1117;
}

html,
body {
  font-family: "onest", "Noto Color Emoji", sans-serif !important;
  background-color: var(--bg-color);
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
  // touch-action: none;

  // &:has(.index-container) {
  ::-webkit-scrollbar {
    display: none;
  }
  // }
}

.container {
  *::selection {
    background-color: #363f4b;
    color: #ffffff;
  }
  display: grid;
  width: 100%;
  max-width: 100vw;
  /* min-height: 600px; */
  display: grid;
  align-content: start;
  justify-content: center;
  grid-template-columns: 1fr;

  &:has(.homepage) {
    grid-template-rows: 1fr;
    height: 100lvh;
  }
}

@media screen and (max-width: 400px) {
  :root {
    --bg-color: #161b22;
  }

  .container {
    grid-template-columns: 100vw;
  }
}
.pc {
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #ffffff66;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}
.text-cursor {
  cursor: default !important;
}
