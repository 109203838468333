:root {
  --options-bg-color: #0d1117ca;
}

.sendimage {
  transition: 1s ease display;
  top: 0;
  left: calc(50vw - clamp(0px, 100vw, 680px) / 2);
  background-color: #000;
  //   background-color: red;
  position: fixed;
  z-index: 110;
  //   padding-top: 60px;
  width: calc(clamp(0px, 100vw, 680px) - 2px);
  height: 100dvh;
  display: grid;
  align-content: start;
  grid-template-rows: 1fr;
  border-right: 1px solid #ffffff17;
  border-left: 1px solid #ffffff17;

  > div {
    color: #fff;
    position: relative;
  }
  #img {
    &-title {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      height: 60px;
      display: grid;
      align-items: center;
      justify-content: space-between;
      grid-auto-flow: column;
      background-color: #000000dc;
      font-weight: 400;
      span {
        margin: 0 10px;
        cursor: pointer;

        &:first-child {
          font-size: clamp(21px, 1vw, 32px);
          letter-spacing: -0.03rem;
          font-weight: 500;
        }
        &:last-child {
          transition: 0.1s ease-in;
          // margin-right: 10px;
          padding: 8px;
          border-radius: 50%;
          &:hover {
            background-color: #23262cab;
          }
        }
      }
    }
    &-container {
      align-self: start;
      justify-self: center;
      height: calc(100dvh);
      // margin-top: 60px;
      display: flex;
      position: relative;
    }
    &-options {
      position: absolute;
      width: 100%;
      bottom: 0;
      display: grid;

      > div {
        display: grid;
        align-items: center;
        grid-auto-flow: column;
        background-color: var(--bg-color);
        width: calc(93% - 20px);
        justify-self: center;
        border-radius: 10rem;
        padding: 7px 10px;
        margin-bottom: 15px;
        justify-items: center;
        grid-template-columns: auto 1fr auto;
        column-gap: 10px;
        input {
          border: none;
          outline: none !important;
          width: 100%;
          padding: 0;
          font-size: clamp(16px, 2vw, 17px);
          caret-color: #3389f9;
        }
        > span {
          font-size: 26px;
          border-radius: 50%;
          padding: 9px;
          cursor: pointer;
          transition: 0.3s;
          &:first-child {
            font-size: 24px;
            &:hover {
              color: inherit;
              background-color: #23262c;
            }
          }
          &:hover {
            color: #3389f9;
            // background-color: #ffffff17;
          }
        }
      }
    }
  }
  #view-img-options {
    position: absolute;
    align-self: start;
    justify-self: center;
    top: 30px;
    column-gap: 30px;
    display: grid;
    grid-auto-flow: column;

    span {
      border-radius: 50%;
      padding: 14px;
      cursor: pointer;
      transition: 0.3s;
      background-color: #23262c;
      z-index: 100;
    }
  }
  img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
    touch-action: pinch-zoom;
  }
}

.viewimage {
  #img-container {
    margin: 0 !important;
    height: 100dvh;
  }
}

@media screen and (max-width: 400px) {
  :root {
    --options-bg-color: #161b22ca;
  }
  .sendimage {
    border: none;
    width: calc(clamp(0px, 100vw, 680px));
  }
}
