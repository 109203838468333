:root {
  --list-x-gap: clamp(24px, 2vw, 29px);
  --thumb-size: clamp(22px, 2vw, 27px);
  --list-max-height: clamp(10px, calc(100vh - 120px), 500px);
}

.chat {
  &Head {
    grid-template-columns: auto auto auto;
    grid-auto-flow: column;

    #dropdown {
      cursor: pointer;
      justify-self: end;
      padding-right: clamp(2px, 1vw, 10px);
      transition: 0.3s;
      margin-right: 5px;

      &.up {
        transform: rotateX(-180deg);
      }
    }
  }
}
.members {
  &-thumb {
    align-self: center;
    background: red;
    justify-self: end;
    padding-right: clamp(0px, 1vw, 30px);
    display: grid;
    grid-auto-flow: column;
    position: relative;
    width: calc(var(--thumb-size) * 1.5);
    height: 0;

    > span:last-child {
      background-color: #2d9dff;
      border: 2px solid var(--bg-color);
      border-radius: 50%;
      padding: clamp(4px, 0.5vw, 5px);
      position: absolute;
      z-index: 4;
      right: clamp(-2px, calc(12px - 1.5vw), 8px);
      bottom: clamp(-4vw, -0.1vw, -10px);
      margin-right: clamp(0px, 1vw, 10px);
    }

    .thumb {
      position: absolute;
      align-self: center;
      margin-left: -5px;
      border: 2px solid var(--bg-color);
      background-color: #2b3543;
      border-radius: 50%;
      width: var(--thumb-size);
      height: var(--thumb-size);
      overflow: hidden;
      display: grid;
      align-content: center;
      cursor: pointer;

      span {
        font-size: clamp(21px, 2.1vw, 28px);
      }

      &:first-child {
        z-index: 2;
      }
      &:nth-child(2) {
        margin-left: calc(clamp(12px, 1.3vw, 19px) / 2);
        z-index: 3;
      }
      &:nth-child(3) {
        margin-left: clamp(17px, 2vw, 24.5px);
        z-index: 3;
      }
    }
  }
  &-list {
    position: absolute;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
    grid-gap: 20px;
    justify-items: center;
    padding: 15px calc(var(--list-x-gap) * 0.8);
    border-radius: 8px;
    right: clamp(calc(10px + 1vw), 2vw, 60px);
    width: fit-content;
    max-width: clamp(0px, 70vw, 450px);
    max-height: var(--list-max-height);
    overflow-y: auto;
    background: #1f2630;
    box-shadow: 0 0 4px 2px #0d1117d2;
    top: 70px;
    transition: 0.3s;
    visibility: visible;
    transform: translateY(20px);

    &#show {
      margin-bottom: 0;
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
    &#hide {
      visibility: hidden;
      opacity: 0;
    }

    .member {
      font-size: calc(var(--thumb-size) * 1.2);
      position: relative;
      cursor: pointer;

      :nth-child(2) {
        background-color: #2d9dff;
        border: 2px solid var(--bg-color);
        border-radius: 50%;
        padding: clamp(4px, 0.5vw, 5px);
        position: absolute;
        z-index: 4;
        right: 0;
        bottom: 10%;
      }
    }
  }
}
